<template>
  <section id="dashboard-ecommerce">
    <div>
      <!-- Filters -->
      <!-- <dashboard-baraem-filters

        :employe-filter.sync="employeFilter"
        :goal-filter.sync="goalFilter"
        :date-filter.sync="date"
        :duration-filter.sync="durationType"
        :role-filter.sync="roleFilter"
        :employe-options="employeOptions"
        :goal-options="goalOptions"
        :role-options="roleOptions"
        :duration-options="durationOptions"
      /> -->

      <!-- Table Container Card -->

      <!-- <b-card
        no-body
        class="mb-0"
      >
   
        <b-card-body>
          <vue-apex-charts
            height="400"

            :options="chartOptions"
            :series="chartOptions.series"
          />

        </b-card-body>
      </b-card> -->
      <hr>
    
      <b-card
        no-body
        class="mb-0"
      >
      <div class="text-center mt-5" style="color: #b4b7bd !important;">

        <h3>
        Case Mangaer Stats
      </h3>
      </div>
     
       
        <b-card-body>
          <b-row>

            <b-col
              md="3"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  label-for="date"
                  :state="errors.length > 0 ? false : null"
                >
                  <label>تاريخ البداية </label>
                  <flat-pickr
                    v-model="start_date"
                    class="form-control"
                    placeholder="حدد تاريخ "
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="3"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  label-for="date"
                  :state="errors.length > 0 ? false : null"
                >
                  <label>تاريخ النهاية </label>
                  <flat-pickr
                    v-model="end_date"
                    class="form-control"
                    placeholder="حدد تاريخ "
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              cols="4"
              md="4"
            >

              <label>Case Manger</label>
              <v-select

                v-model="caseMangerId"
                :options="CaseManagerOption"
                :reduce="option => option.value"
                :clearable="true"
              />

            </b-col>
            
          </b-row>
       
          <b-row lass="match-height" v-if = "caseMangerId">
            <b-col lg="6"   v-for="(item, i) in caseData" :key="i" >

<b-card>
<b-card-header>
<b-card-title>الخدمة الفرعية: {{ item.proposal_service_name }}</b-card-title>
</b-card-header>
<b-card-body>
<vue-apex-charts
:id="'chart-' + i"
height="400"
:options="generateChartOptions(item.stats)"
:series="generateSeries(item.stats)"
/>
</b-card-body>
</b-card>


</b-col>
          </b-row>
       
          <div v-else class="text-center mt-5" style="color: #b4b7bd !important;">

                    <h5>
                    Select Case Manger To Display Statistics
                    </h5>
</div>
         
       
        </b-card-body>
      </b-card>
      <hr>

      <b-card
        no-body
        class="mb-0"
        title="Coordinator"
      >
        
        <div class="text-center mt-5" style="color: #b4b7bd !important;">

<h3>
  Coordinator
</h3>
</div>
        <b-card-body>
          

          <b-row>

<b-col
  md="3"
  xl="4"
>
  <validation-provider
    #default="{ errors }"
    rules="required"
  >
    <b-form-group
      label-for="date"
      :state="errors.length > 0 ? false : null"
    >
      <label>تاريخ البداية </label>
      <flat-pickr
        v-model="start"
        class="form-control"
        placeholder="حدد تاريخ "
      />
    </b-form-group>
    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
      {{ errors[0] }}
    </b-form-invalid-feedback>
  </validation-provider>
</b-col>
<b-col
  md="3"
  xl="4"
>
  <validation-provider
    #default="{ errors }"
    rules="required"
  >
    <b-form-group
      label-for="date"
      :state="errors.length > 0 ? false : null"
    >
      <label>تاريخ النهاية </label>
      <flat-pickr
        v-model="end"
        class="form-control"
        placeholder="حدد تاريخ "
      />
    </b-form-group>
    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
      {{ errors[0] }}
    </b-form-invalid-feedback>
  </validation-provider>
</b-col>
<b-col
  cols="4"
  md="4"
>

  <label>Coordinator</label>
  <v-select

    v-model="coorMangerId"
    :options="CoorOption"
    :reduce="option => option.value"
    :clearable="true"
  />

</b-col>

</b-row>




<div v-if="coorMangerId">
  <div class="text-center mt-5" style="color: #b4b7bd !important;" v-if="mainService">
<h2>
          إحصائيات الخدمة الرئيسية:  {{mainService}}
      </h2>
    </div>

<b-row class="" >
      <b-col lg="6" sm="6">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" variant="light-primary" size="45">
              <feather-icon size="21" icon="UsersIcon" />
            </b-avatar>
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ male }}
              </h2>
              <span>   عدد الذكور</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="6" sm="6">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" variant="light-danger" size="45">
              <feather-icon size="21" icon="UsersIcon" />
            </b-avatar>
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ female }}
              </h2>
              <span> عدد الإناث</span>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- <b-col
        lg="12"
        md="12"
      >
        <analytics-congratulation :data="data2.congratulations" />
      </b-col> -->
    </b-row>
<br />
<br />
<b-row>
  
        <b-col>
          <vue-apex-charts
            height="400"

            :options="chartOptionsCoordinator"
            :series="chartOptionsCoordinator.series"
          />
        </b-col>
      </b-row>
<br />
<br />
<b-row>
        <b-col
          v-for="(item,index)  in personsPerSubService"
          :key="index"
          xl="3"
          sm="6"
         
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="DatabaseIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <label>
                {{ item.service_name }}
                </label>
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.persons_count }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
</div>

<div v-else  class="text-center mt-5" style="color: #b4b7bd !important;">

<h5>
Select Coordinator To Display Statistics
</h5>
</div>

        </b-card-body>
      </b-card>
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BCardHeader,
  BCardBody,
  BFormInvalidFeedback,
  bCardTitle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import { ref, onUnmounted } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import dashboardBaraemFilters from './dashboardBaraemFilters.vue'
import usedashboardBaraemList from './usedashboardBaraemList'
import 'flatpickr/dist/flatpickr.css'
import DashboardStoreModule from './DashboardStoreModule'

export default {
  setup() {
    const DASHBOARD_APP_STORE_MODULE_NAME = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) { store.registerModule(DASHBOARD_APP_STORE_MODULE_NAME, DashboardStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) { store.unregisterModule(DASHBOARD_APP_STORE_MODULE_NAME) }
    })

    const goalOptions = [
      { value: 0, label: 'قسم التسجيل ' },
      { value: 1, label: ' مدراء الحالة' },
      { value: 2, label: ' الفريق الجوال' },
    ]

    const durationOptions = [
      { value: 0, label: ' يومي' },
      { value: 1, label: '  شهري' },
      { value: 2, label: '  سنوي' },
    ]
    const {
      roleOptions,
      fetchBenServices,
      employeOptions,
      tableColumns,

      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,

      // durationType,
      // date,

      // goalFilter,
      // employeFilter,
      // roleFilter,
      openStatus,
      changeStatus,
      // chartOptions,

      succesStory,
      series,

    } = usedashboardBaraemList()

    return {
      // Sidebar
      series,
      durationOptions,
      employeOptions,
      // chartOptions,
      fetchBenServices,
      changeStatus,

      openStatus,
      succesStory,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,

      // Filter
      // durationType,
      // date,
      // goalFilter,
      // employeFilter,
      // roleFilter,

      goalOptions,
      roleOptions,

    }
  },
  components: {
    VueApexCharts,
    dashboardBaraemFilters,
    bCardTitle,
    BButton,
    
    BDropdownItemButton,
    BFormInvalidFeedback,

    BCard,
    BRow,
    BCol,
    BFormInput,

    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      mainService:'',
      male:'',
      female:'',
    subService: [],
      label: [],
     
      caseData:{},
      CaseManagerOption: [],
      CoorOption:[],
      goalFilter: '',
      date: '',
      durationType: '',
      roleFilter: '',
      employeFilter: '',
      chartOptionsCoordinator: {
        series: [],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },

        noData: {
          text: 'No Data...',
        },
        plotOptions: {
          pie: {

            donut: {
              labels: {
                show: true,

              },
            },
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        }],
        // labels: ['قيد الانتظار', 'مفتوحة', 'مغلقة', 'بانتظار الموافقة', 'حالة جديدة من قسم التسجيل', 'تم تحويلها'],

      },
      chartOptionsCaseMangaer: {
        series: [
         ],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },
        plotOptions: {
          pie: {

            donut: {
              labels: {
                show: true,

              },
            },
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        }],

        dataLabels: {
          enabled: false,
        },
        noData: {
          text: 'No Data...',
        },
        // labels: ['قيد الانتظار', 'مفتوحة', 'مغلقة', 'بانتظار الموافقة', 'حالة جديدة من قسم التسجيل', 'تم تحويلها'],

      },
      chartOptions: {
        series: [],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#ffe700', '#FFA1A1'],

        labels: ['  الهدف  ', '  الانجاز  '],

        plotOptions: {
          pie: {

            donut: {
              labels: {
                show: true,

                total: {
                  show: true,
                  offsetY: 15,

                },
              },
            },
          },
        },

      },
      caseMangerId: '',
      coorMangerId:'',
      personsPerSubService: [],
      end_date: '',
      start_date: '',
      end: '',
      start: '',
    }
  },
  watch: {
    goalFilter() {
      this.getData()
    },
    date() {
      this.getData()
    },
    durationType() {
      this.getData()
    },
    employeFilter() {
      this.getData()
    },
    roleFilter() {
      this.getData()
    },
    start_date() {
      this.getCaseMangerStats()
    },
    end_date() {
      this.getCaseMangerStats()
    },
    caseMangerId() {
      this.getCaseMangerStats()
    },
    coorMangerId(){
      this.getCoordinator()
    },
    start() {
      this.getCoordinator()
    },
    end() {
      this.getCoordinator()
    },

  },
  created() {
    let today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = today.getFullYear()

    today = `${yyyy}-${mm}-${dd}`
    this.end_date = today
    this.start_date =  today
    this.start =  today

    this.end = today

    this.date = today
    // this.getCaseMangerStats()
    // this.getCoordinator()
    this.getCaseMangerOption()
    this.getCoorOption()
  },
  methods: {

    generateChartOptions(item) {
    return {
      chart: {
        type: 'pie',
        // You can customize chart options here
      },
      labels: item.labels,
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {

            donut: {
              labels: {
                show: true,

              },
            },
          },
        },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
        
          legend: {
            position: 'bottom'
          }
        }
      }]
    };},
    generateSeries(item) {
      const temp = []
      item.values.forEach(el => {
          temp.push(parseInt(el))
        
      })
      return temp;
    },

    getCaseMangerOption() {
      const url = '/api/v1/information_manager/case-managers'
      this.$http.get(url).then(res => {
        window.dispatchEvent(new Event('resize'))
       // console.log("CaseManagerOption",res.data.data)
        res.data.data.forEach((el) => {
          this.CaseManagerOption.push({ value: el.id, label: `${el.first_name} ${el.last_name} `

      })
    })})
    },
      getCoorOption() {
      const url = '/api/v1/get-all-coordinator'
      this.$http.get(url).then(res => {
        window.dispatchEvent(new Event('resize'))
      //  console.log("CoorManagerOption",res.data)
        res.data.forEach((el) => {
          this.CoorOption.push({ value: el.id, label: `${el.first_name} ${el.last_name} `

      })
    })})
    },
    getCaseMangerStats() {
      const url = `/api/v1/stats/case-manager?start_date=${this.start_date}&end_date=${this.end_date}&user_id=${this.caseMangerId}`
      this.$http.get(url).then(res => {
        window.dispatchEvent(new Event('resize'))
     //   console.log(res.data.data)
        this.caseData = res.data.data
        // this.chartOptionsCaseMangaer.xaxis.categories = res.data.data[0].stats.labels
        // this.chartOptionsCaseMangaer.series = res.data.data[0].stats.values
        res.data.data[0].stats.values.forEach(el => {
          this.chartOptionsCaseMangaer.series.push(parseInt(el))
        
      })
      // res.data.data[0].stats.labels.forEach(el => {
      //     this.x.push(el)
        
      // })
     this.chartOptionsCaseMangaer.labels= res.data.data[0].stats.labels
      // console.log( this.chartOptionsCaseMangaer.labels)
    
      })
    },
    getCoordinator() {
      const url = `/api/v1/stats/get-stats-coordinator?start_date=${this.start}&end_date=${this.end}&user_id=${this.coorMangerId}`
      this.$http.get(url).then(res => {
        window.dispatchEvent(new Event('resize'))
        this.male = res.data.data.male
        this.female = res.data.data.female
        this.personsPerSubService = res.data.data.personsPerSubService
        const data = res.data.data.personsPerMainService[0].stats
        this.mainService = res.data.data.personsPerMainService[0].main_service_name
        this.subService = res.data.data.personsPerSubService
        
       
        data.values.forEach(el => {
          this.chartOptionsCoordinator.series.push(parseInt(el))
        
      })
      this.chartOptionsCoordinator = {labels: data.labels}


        window.dispatchEvent(new Event('resize'))
      })
    },

    getData() {
      window.dispatchEvent(new Event('resize'))
      this.$http.get(`/api/v1/achievement_target_stats?goal_type=${this.goalFilter}&date=${this.date}&is_summary=true&employee_id=${this.employeFilter}&duration_type=${this.durationType}
      `)
        .then(response => {
          window.dispatchEvent(new Event('resize'))
          // console.log("stat",response)
          // console.log("stat",response.data.total_goal)
          const data = []

          if (response.data) {
            data.push(parseInt(response.data.inserted_families_stats.total_goal))
            data.push(parseInt(response.data.inserted_families_stats.total_inserted_families))
            this.chartOptions.series = data
            window.dispatchEvent(new Event('resize'))
          }
        })
    },
  },
}

</script>

<style lang="scss">
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.chart-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.chart-item {
  flex: 1;
  margin: 0 10px;
}
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
